import { IconButton, Box, Popover, Grid, GridJustification } from "@material-ui/core"
import { HelpOutlined } from "@material-ui/icons"
import { useState } from "react"
import russianRules from "../../utils/russianRules"

type Prop = {
  anchorOrigin?: any
  transformOrigin?: any
  comment?: any
  justify?: GridJustification
}

const Status: React.FC<Prop> = ({ anchorOrigin = {}, transformOrigin = {}, children, comment, justify = "center" }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Grid container spacing={1} justify={justify} wrap="nowrap" alignItems="center">
      {children && <Grid item>{children}</Grid>}
      {comment && (
        <Grid item>
          <IconButton
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            color="primary"
            style={{
              fontSize: "120%",
              width: 24,
              height: 24,
              padding: 0
            }}
            onClick={handlePopoverOpen}
            tabIndex={-1}
          >
            <HelpOutlined />
          </IconButton>
          <Popover
            style={{ maxWidth: 200, textTransform: "uppercase", fontWeight: 600 }}
            disableRestoreFocus
            id="mouse-over-popover"
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
              ...anchorOrigin
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
              ...transformOrigin
            }}
          >
            <Box p={2}>{russianRules(comment)}</Box>
          </Popover>
        </Grid>
      )}
    </Grid>
  )
}

export default Status
