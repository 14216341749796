import { useEffect, useState } from "react"
import { Box, Button, Grid, Hidden, Table, TableBody, TableCell, TableHead, TableRow, Theme, useMediaQuery } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import Moment from "react-moment"
import { useHistory } from "react-router-dom"
import { ArrowRightAlt } from "@material-ui/icons"
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from "classnames"
import { AnketaOffspring, Entity } from "../../../types/user"
import Status from "../../../components/status"
import { post } from "../../../api/actions"
import { Msg } from "../../../modal/globalAlert/alert"
import useStyles from "./offspring.style"

import { GlobalAlertModal } from "../../../modal/globalAlert"
import { GlobalConfirmAlert, Msg as ConfirmMsg } from "../../../modal/globalConfirmAlert/globalConfirmAlert"
import Answer from "../../../utils/answer"

const countArray = (ar: any) => (ar ? ar.length : 0)

const BuyersList: React.FC<{ items: AnketaOffspring[]; loadInfo: any }> = ({ items = [], loadInfo }) => {
  const [page, setPage] = useState<number>(1)
  const [msg, setMsg] = useState<Msg>()
  const [confirmMsg, setConfirmMsg] = useState<ConfirmMsg>()
  const [processed, setProcessed] = useState<boolean>(false)
  const perPage = 5

  const classes = useStyles()
  const history = useHistory()

  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"))

  const handleChange = (_event: any, value: number) => {
    setPage(value)
  }

  const [i, setI] = useState<AnketaOffspring[]>([])

  useEffect(() => {
    if (items) setI([...items].reverse())
  }, [items])

  const handleOffspringClick = (id: number, breeder_anketa_offspring_status: Entity) => {
    if (breeder_anketa_offspring_status.key === "confirm") {
      history.push(`/profile?offspring=${id}#owner`)
    }
  }

  const createGift = (id: number, offspring: number) => {
    setConfirmMsg({
      message: "Вы действительно хотите заказать выкорм?",
      onOk: () => {
        setConfirmMsg(undefined)
        post(`/user/breeder/anketa_offspring/${offspring}/create_gift`, { breeder_gift_type_id: id })
          .then((res) => {
            loadInfo()
            setMsg({
              message: Answer(res.message),
              onClose: () => {
                setMsg(undefined)
                setProcessed(false)
              }
            })
          })
          .catch(() => {})
          .finally(() => {
            setProcessed(false)
          })
      },
      onClose: () => {
        setConfirmMsg(undefined)
        setProcessed(false)
      }
    })
    setProcessed(true)
  }

  return (
    <>
      <GlobalAlertModal msg={msg} />
      <GlobalConfirmAlert msgs={confirmMsg} />

      <Table>
        <TableHead>
          <TableRow>
            <Hidden mdDown>
              <TableCell />
              <TableCell>Дата помета</TableCell>
              <TableCell>Порода</TableCell>
              <TableCell>Щенки/котята</TableCell>
              <TableCell>Владельцы</TableCell>
              <TableCell>Подарки</TableCell>
              <TableCell>Заказать</TableCell>
              <TableCell>Статус</TableCell>
            </Hidden>
          </TableRow>
        </TableHead>

        <TableBody>
          {
            i.length > 0 &&
              i.slice(perPage * (page - 1), page * perPage).map((offspring) => {
                const { id, birthday_at, breed, note, breeder_anketa_offspring_status: status, offspring_count, breeder_anketa_owner, breeder_gift, breeder_gift_aviable } = offspring

                return (
                  <TableRow key={`offspring-${id}`} className={classNames(status.key === "confirm" ? classes.link : "", classes.btn)}>
                    <Hidden mdDown>
                      {/* Информация (кнопка) */}
                      <TableCell className={classNames(status.key === "confirm" ? "link" : "", classes.btn)} onClick={() => handleOffspringClick(id, status)}>
                        {status.key === "confirm" && <ArrowRightAlt style={{ paddingTop: 5 }} />}
                      </TableCell>

                      {/* Дата помета */}
                      <TableCell>
                        <Moment format="DD.MM.yyyy">{birthday_at}</Moment>
                      </TableCell>

                      {/* Порода */}
                      <TableCell>{breed.title}</TableCell>

                      {/* Щенки/котята */}
                      <TableCell>{offspring_count}</TableCell>

                      {/* Владельцы */}
                      <TableCell>{breeder_anketa_owner?.length || 0}</TableCell>

                      {/* Подарки */}
                      <TableCell>
                        {breeder_gift && breeder_gift.length > 0 ? (
                          breeder_gift.map(({ id: gift_id, pivot, title, breeder_gift_type }) => (
                            <Box key={`gift${gift_id}`} style={{}}>
                              <Status comment={`${title}<br/><b>${pivot.breeder_gift_status.title}</b><br/><i>${pivot.note_client || ""}</i>`}>{breeder_gift_type?.title}</Status>
                            </Box>
                          ))
                        ) : (
                          <>-</>
                        )}
                      </TableCell>

                      {/* Заказать */}
                      <TableCell className={classes.btn}>
                        {breeder_gift_aviable.map(({ id: gift_id, title }) => (
                          <Button disabled={processed} onClick={() => createGift(gift_id, id)} variant="contained" color="secondary" size="small">
                            {title}
                          </Button>
                        ))}
                        {breeder_gift_aviable.length === 0 && <Box>-</Box>}
                      </TableCell>

                      {/* Статус */}
                      <TableCell>
                        <Status comment={status?.description}>{status?.title}</Status>
                      </TableCell>
                    </Hidden>

                    <Hidden lgUp>
                      <TableCell>
                        <Box textAlign="left" fontSize={14}>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Grid container direction="column" spacing={1}>
                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item className="m_cell_title">
                                      Дата помета
                                    </Grid>
                                    <Grid item xs={12} className="m_cell">
                                      <Moment format="DD.MM.yyyy">{birthday_at}</Moment>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item className="m_cell_title">
                                      Порода
                                    </Grid>
                                    <Grid item xs={12} className="m_cell">
                                      {breed.title}
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item className="m_cell_title">
                                      Щенки/Котята
                                    </Grid>
                                    <Grid item xs={12} className="m_cell">
                                      {offspring_count}
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item className="m_cell_title">
                                      Владельцы
                                    </Grid>
                                    <Grid item xs={12} className="m_cell">
                                      {breeder_anketa_owner?.length || 0}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={6}>
                              <Grid container direction="column" spacing={1}>
                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item className="m_cell_title">
                                      Подарки
                                    </Grid>
                                    <Grid item xs={12} className="m_cell">
                                      {breeder_gift && breeder_gift.length > 0 ? (
                                        breeder_gift.map(({ id: gift_id, pivot, title, breeder_gift_type }) => (
                                          <Box key={`gift${gift_id}`} style={{ whiteSpace: "nowrap" }}>
                                            <Status justify="flex-start" comment={`${title}<br/><b>${pivot.note_client}</b><br/><i>${note || ""}</i>`}>
                                              {breeder_gift_type?.title}
                                            </Status>
                                          </Box>
                                        ))
                                      ) : (
                                        <>-</>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item className="m_cell_title">
                                      Статус
                                    </Grid>
                                    <Grid item xs={12} className="m_cell">
                                      <Status comment={status?.description}>{status?.title}</Status>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Box mt={2}>
                                <Grid container justify="center" spacing={sm ? 1 : 2}>
                                  {breeder_gift_aviable.map(({ id: gift_id, title }) => (
                                    <Grid item xs={12} sm="auto">
                                      <Button fullWidth={sm} disabled={processed} onClick={() => createGift(gift_id, id)} variant="contained" color="primary">
                                        Заказать {title}
                                      </Button>
                                    </Grid>
                                  ))}

                                  {status.key === "confirm" && (
                                    <Grid item xs={12} sm="auto">
                                      <Button fullWidth={sm} color="primary" variant="contained" onClick={() => handleOffspringClick(id, status)}>
                                        Владельцы
                                      </Button>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                    </Hidden>
                  </TableRow>
                )
              })
            //  : (
            //   <TableRow>
            //     <TableCell colSpan={8}>Здесь будет информация о помёте</TableCell>
            //   </TableRow>
            // )
          }
        </TableBody>
      </Table>
      {countArray(items) > perPage && (
        <Box pt={4} textAlign="center">
          <Pagination page={page} variant="text" onChange={handleChange} count={Math.ceil(countArray(items) / perPage)} />
        </Box>
      )}
    </>
  )
}

export default BuyersList
