import { date, breed_species_id, breed_id, validation, number_required, imageDropzone, breeder_gift_group_id } from "../../../../components/form-control/validation"

const validationShema = validation({
  birthday_at: date,
  breed_species_id,
  breed_id,
  offspring_count: number_required,
  image_file: imageDropzone,
  breeder_gift_group_id
})

export default validationShema
