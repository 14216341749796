import { Box, Grid, Typography, Button, useMediaQuery, useTheme } from "@material-ui/core"
import { useDispatch } from "react-redux"
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from "classnames"
import { useEffect, useState } from "react"
import { BreederGiftType, User } from "../../../types/user"
import BuyersList from "./offspring-list"
import { SET_PROPS } from "../../../store/props/types"
import { modalName as BuyerAddModalName } from "../modal/offspring"
import OffspringPackage from "./offspring-package"
import russianRules from "../../../utils/russianRules"
import useStyles from "./offspring.style"

const Offspring: React.FC<{ user: User; loadInfo: any }> = ({ user, loadInfo }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { breeder_anketa_offspring, breeder_gift_type_parameter = [] } = user || {}
  const theme = useTheme()

  const [fooderGlobal, setFooderGlobal] = useState<BreederGiftType>()
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const lg = useMediaQuery(theme.breakpoints.up("lg"))
  const sm = useMediaQuery(theme.breakpoints.down("xs"))

  useEffect(() => {
    breeder_gift_type_parameter
      .filter((gift) => gift.breeder_gift_type.key === "fodder")
      // eslint-disable-next-line array-callback-return
      .map((i) => {
        setFooderGlobal(i)
      })
  }, [breeder_gift_type_parameter])

  return (
    <Grid container justify="flex-start" className="animated fadeIn" spacing={4}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid container direction="column" style={{ height: "100%" }}>
          <Grid item style={{ flex: "0 1 auto", minHeight: md ? 55 : 20, paddingBottom: md ? 0 : 10 }}>
            <Grid container alignItems="center" alignContent="center" spacing={lg ? 2 : 0}>
              <Grid item xs={12} md="auto">
                <Typography variant="h4" color="secondary">
                  ВЫКОРМ
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={{ flex: "1 1 auto" }}>
            <Grid container alignItems="stretch" spacing={2} style={{ height: "calc(100% + 16px)" }}>
              <Grid item xs={12}>
                <Grid container alignItems="center" style={{ height: "100%" }} className={classNames(classes.box1, "animated", "zoomIn faster")}>
                  <Grid item xs={12}>
                    <Box className="label">Доступно (шт.)</Box>

                    <Box className="ball" style={{ justifyContent: "center" }}>
                      {fooderGlobal && (fooderGlobal.limit_period - fooderGlobal?.count > 0 ? fooderGlobal.limit_period - fooderGlobal?.count : 0)}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid container alignItems="center" style={{ height: "100%" }} className={classNames(classes.box2, "animated", "zoomIn faster")}>
                  <Grid item xs={12} style={{ height: "50%" }}>
                    <Grid container style={{ height: "100%" }} alignItems="center">
                      <Grid item xs={12}>
                        <Box className="label">Заказано (шт.)</Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="ball" style={{ justifyContent: "center" }}>
                      {fooderGlobal?.count ?? 0}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center" style={{ height: "100%" }} className={classNames(classes.box2, "animated", "zoomIn faster")}>
                  <Grid item xs={12} style={{ height: "50%" }}>
                    <Grid container style={{ height: "100%" }} alignItems="center">
                      <Grid item xs={12}>
                        <Box className="label label_long">{russianRules("всего выкорма в год (шт.)")}</Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="ball" style={{ justifyContent: "center" }}>
                      {fooderGlobal?.limit_period ?? 0}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid container direction="column" style={{ height: "100%" }}>
          <Grid item style={{ flex: "0 1 auto", minHeight: md ? 55 : 20, paddingBottom: md ? 0 : 10 }}>
            <Typography variant="h4" color="secondary">
              Мой пакет
            </Typography>
          </Grid>
          <Grid item style={{ flex: "1 1 auto" }}>
            <OffspringPackage user={user} loadInfo={loadInfo} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box py={5} className={classes.root}>
          <Box pb={{ xs: 2, sm: 4 }} px={{ xs: 2, sm: 0 }}>
            <Typography variant="h5">{russianRules("Добавьте информацию о помётах своих питомцев<br /> и закажите выкорм для помёта.")}</Typography>
          </Box>

          <Box px={2}>
            <Button
              fullWidth={sm}
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch({
                  type: SET_PROPS,
                  payload: { modal: { [BuyerAddModalName]: { show: true } } }
                })
              }}
            >
              Добавить помёт
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.list}>
          <BuyersList items={breeder_anketa_offspring} loadInfo={loadInfo} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default Offspring
