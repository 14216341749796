import { Box, Button, Grid } from "@material-ui/core"
import Dialog from "../../components/dialog"
import russianRules from "../../utils/russianRules"

export type Msg = {
  title?: string
  message?: any
  onOk?: any
  onClose?: any
  onCancel?: any
  btnOk?: string
  btnCancel?: string
  showCancel?: boolean
}

export const GlobalConfirmAlert: React.FC<{ msgs?: Msg }> = ({ msgs }) => {
  const { title = "Подтвердите действие", message, onClose = () => {}, onOk = () => {}, btnOk = "Да", btnCancel = "Нет", showCancel = true, onCancel } = msgs || {}

  return (
    <>
      <Dialog title={title} maxWidth="sm" onOpen={() => {}} onCloseDialog={onClose} name="alert-modal" open={message !== undefined}>
        {russianRules(message)}
        <Box pt={5}>
          <Grid container justify="center" spacing={2}>
            {showCancel && (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    const callback = onCancel || onClose
                    if (callback) {
                      callback()
                    }
                  }}
                >
                  {btnCancel}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button variant="contained" color="secondary" onClick={onOk}>
                {btnOk}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  )
}
