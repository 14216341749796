import { useEffect, useState } from "react"
import { Box, Table, TableRow, TableCell, TableHead, TableBody, Hidden, Grid, Button } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { ClassNameMap } from "@material-ui/styles"
import Moment from "react-moment"
import { useDispatch } from "react-redux"
import { Exhibition } from "../../../types/user"
import CustomLink from "../../../components/link"
import Status from "../../../components/status"
import BallView from "../../../components/ball/ball"
import Address from "../../../utils/address"
import { OpenViewer } from "../../../modal/viewer/viewer"

const countArray = (ar: any) => (ar ? ar.length : 0)

const ExhibitionList: React.FC<{ items: Exhibition[]; classes: ClassNameMap<any> }> = ({ items = [], classes }) => {
  const [page, setPage] = useState<number>(1)
  const dispatch = useDispatch()
  const perPage = 5

  const [exhib, setExhib] = useState<Exhibition[]>([])
  useEffect(() => {
    if (items) setExhib([...items].reverse())
  }, [items])

  const handleChange = (_event: any, value: number) => {
    setPage(value)
  }

  return (
    <>
      <Table key="ExhibitionList">
        <TableHead>
          <TableRow>
            <Hidden smDown>
              <TableCell>ДАТА выставки</TableCell>
              <TableCell>Место проведения</TableCell>
              <TableCell>титул</TableCell>
              <TableCell>ФОТО ДОКУМЕНТА</TableCell>
              <TableCell>СТАТУС ПРОВЕРКИ</TableCell>
              <TableCell>БАЛЛЫ</TableCell>
            </Hidden>
          </TableRow>
        </TableHead>

        <TableBody>
          {exhib.length > 0 ? (
            exhib.slice(perPage * (page - 1), page * perPage).map((exhibition: Exhibition) => {
              const {
                id,
                city,
                region,
                np,
                area,
                country,
                date_at,
                breeder_anketa_exhibition_status: status,
                breeder_anketa_exhibition_status_comment: status_comment,
                primary_src,
                primary_is_image,
                breeder_balance
              } = exhibition

              return (
                <TableRow key={`exhibition-${id}`}>
                  <Hidden smDown>
                    <TableCell>
                      <Moment format="DD.MM.yyyy">{date_at}</Moment>
                    </TableCell>

                    <TableCell>
                      <Address region={region} country={country} np={np} city={city} area={area} />
                    </TableCell>

                    <TableCell>
                      <Box className="nobr">{breeder_balance?.breeder_balance_category?.title || "-"}</Box>
                    </TableCell>

                    <TableCell>
                      {primary_src && (
                        <Button variant="text" color="primary" onClick={() => OpenViewer(primary_src, dispatch)}>
                          <img src="/imgs/foto.svg" alt="" />
                        </Button>
                      )}
                    </TableCell>

                    <TableCell>
                      <Status comment={status_comment}>{status.title}</Status>
                    </TableCell>

                    <TableCell>
                      <BallView>{breeder_balance?.score}</BallView>
                    </TableCell>
                  </Hidden>

                  <Hidden mdUp>
                    <TableCell>
                      <Box textAlign="left" fontSize={14}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Grid container direction="column" spacing={1}>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item className="m_cell_title">
                                    Дата выставки
                                  </Grid>
                                  <Grid item xs={12} className="m_cell">
                                    <Moment format="DD.MM.yyyy">{date_at}</Moment>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item className="m_cell_title">
                                    Место проведения
                                  </Grid>
                                  <Grid item xs={12} className="m_cell">
                                    <Address region={region} np={np} city={city} area={area} />
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item className="m_cell_title">
                                    Титул
                                  </Grid>
                                  <Grid item xs={12} className="m_cell">
                                    <Box className="nobr">{breeder_balance?.breeder_balance_category?.title || "-"}</Box>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item className="m_cell_title">
                                    Статус проверки
                                  </Grid>
                                  <Grid item xs={12} className="m_cell">
                                    <Status justify="flex-start" comment={status_comment}>
                                      {status.title}
                                    </Status>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={6}>
                            <Grid container direction="column" alignItems="flex-end" spacing={1}>
                              <Grid item xs={12}>
                                <Box pt={1} fontSize={18}>
                                  <BallView style={{ justifyContent: "flex-start" }}>{breeder_balance?.score}</BallView>
                                </Box>
                              </Grid>

                              <Grid item xs={12}>
                                {primary_is_image && primary_src && (
                                  <CustomLink target="_blank" href={primary_src} className={classes.link}>
                                    Фото документа
                                  </CustomLink>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </TableCell>
                  </Hidden>
                </TableRow>
              )
            })
          ) : (
            <TableRow>
              <TableCell colSpan={6}>Здесь будет информация о выставках</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {countArray(items) > perPage && (
        <Box pt={4} textAlign="center">
          <Pagination page={page} variant="text" onChange={handleChange} count={Math.ceil(countArray(items) / perPage)} />
        </Box>
      )}
    </>
  )
}

export default ExhibitionList
