const Address: React.FC<{ city?: string; country?: string; area?: string; np?: string; region?: string }> = ({ city, region, area, np, country }) => {
  return (
    <>
      {country && (city || area || np) ? `${country}, ` : ""}
      {country && !(city || area || np) ? `${country}` : ""}
      {(city || area || np) && region ? `${region}, ` : region}
      {(area || np) && city ? `${city}, ` : city}
      {np && area ? `${area}, ` : area}
      {np}
    </>
  )
}

export default Address
